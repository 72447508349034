import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  FormHelperText,
  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';

interface ContactUsProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  shape: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 70,
    '& > img': {
      maxWidth: '70%',
      height: 'auto'
    }
  }
}));

const ContactUs: FC<ContactUsProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
        >
          Chat with us
        </Typography>
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          Let's grab a coffee and jump on conversation
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <div className={classes.shape}>
                <img
                  alt="Image"
                  src="/static/images/undraw_chatting_re_j55r.svg"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              {/* <Box display="flex"> */}
              <Formik
                initialValues={{
                  name: '',
                  number: '',
                  email: '',
                  message: '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().max(255).required('Your name is required'),
                  message: Yup.string().max(255).required('message is required'),
                })}
                onSubmit={async (values, {
                  setErrors,
                  setStatus,
                  setSubmitting
                }) => {
                  // try {
                  //   await createUserWithEmailAndPassword(values.email, values.password, values.firstName, values.lastName, referralId);
          
                  //   if (isMountedRef.current) {
                  //     setStatus({ success: true });
                  //     setSubmitting(false);
                  //   }
                  // } catch (err) {
                  //   console.error(err);
                  //   if (isMountedRef.current) {
                  //     setStatus({ success: false });
                  //     setErrors({ submit: err.message });
                  //     setSubmitting(false);
                  //   }
                  // }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form
                    noValidate
                    // className={clsx(classes.root, className)}
                    onSubmit={handleSubmit}
                    {...rest}
                  >
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Your Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Your Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.number && errors.number)}
                      fullWidth
                      helperText={touched.number && errors.number}
                      label="Your contact number"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.number}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.message && errors.message)}
                      fullWidth
                      helperText={touched.message && errors.message}
                      label="Message"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      multiline
                      rows={4}
                      maxRows={10}
                      value={values.message}
                      variant="outlined"
                    />
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {errors.submit}
                        </FormHelperText>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        // fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Send
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

ContactUs.propTypes = {
  className: PropTypes.string
};

export default ContactUs;
