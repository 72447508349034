import moment from 'moment';
import mock from 'src/utils/mock';
import type {
  Connection,
  Post,
  Profile
} from 'src/types/social';

mock.onGet('/api/social/profile').reply(() => {
  const profile: Profile = {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/images/avatars/avatar_12.png',
    bio: 'Global Ambassador',
    connectedStatus: 'not_connected',
    cover: '/static/images/covers/cover_1.jpg',
    currentCity: 'Bucharest',
    currentJob: {
      company: 'EC Global Family',
      title: 'Global Ambassador'
    },
    email: 'katarina.smith@devias.io',
    name: 'Neil Art Aces',
    originCity: 'Rm. Valcea',
    previousJob: {
      company: 'Focus Aesthetic Dyanmics',
      title: 'UX Designer at'
    },
    profileProgress: 50,
    quote: 'Everyone thinks of changing the world, but no one thinks of changing himself.'
  };

  return [200, { profile }];
});

mock.onGet('/api/social/connections').reply(() => {
  const connections: Connection[] = [
    {
      id: '5e887ac47eed253091be10cb',
      avatar: '/static/images/avatars/avatar_3.png',
      commonConnections: 10,
      name: 'Cao Yu',
      status: 'rejected'
    },
    {
      id: '5e887b209c28ac3dd97f6db5',
      avatar: '/static/images/avatars/avatar_4.png',
      commonConnections: 8,
      name: 'Neil Art Aces',
      status: 'pending'
    },
    {
      id: '5e86805e2bafd54f66cc95c3',
      avatar: '/static/images/avatars/avatar_7.png',
      commonConnections: 5,
      name: 'Adam Denisov',
      status: 'not_connected'
    },
    {
      id: '5e887a1fbefd7938eea9c981',
      avatar: '/static/images/avatars/avatar_8.png',
      commonConnections: 1,
      name: 'Miller Edwards',
      status: 'connected'
    }
  ];

  return [200, { connections }];
});

mock.onGet('/api/social/posts').reply(() => {
  const posts: Post[] = [
    {
      id: '5e887faca2b7a1ddce01221a',
      author: {
        id: '5e86809283e28b96d2d38537',
        avatar: '/static/images/avatars/avatar_12.png',
        name: 'Neil Art Aces'
      },
      comments: [
        {
          id: '5e887fc17162ba254da30771',
          author: {
            id: '5e887b7602bdbc4dbb234b27',
            avatar: '/static/images/avatars/avatar_12.png',
            name: 'Neil Art Aces'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          message: 'Power!!!'
        },
        // {
        //   id: '5e887fc759bebe8d5d54a2e5',
        //   author: {
        //     id: '5e887a1fbefd7938eea9c981',
        //     avatar: '/static/images/avatars/avatar_8.png',
        //     name: 'Miller Edwards'
        //   },
        //   createdAt: moment()
        //     .subtract(2, 'hours')
        //     .toDate()
        //     .getTime(),
        //   message: 'Hmm, honestly this looks nice but I would change the shadow though',
        // }
      ],
      createdAt: moment()
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      isLiked: true,
      likes: 24,
      media: '/static/images/posts/post_1.png',
      message: 'Enroll Now!'
    },
    {
      id: '5e887faf03e78a5359765636',
      author: {
        id: '5e86809283e28b96d2d38537',
        avatar: '/static/images/avatars/avatar_12.png',
        name: 'EC Global Family'
      },
      comments: [
        {
          id: '5e887fde4992eca63b9e9ef5',
          author: {
            id: '5e8877da9a65442b11551975',
            avatar: '/static/images/avatars/avatar_6.png',
            name: 'Kim Toluan'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`
        },
        {
          id: '5e887feb11b7add1ebfcca78',
          author: {
            id: '5e887b209c28ac3dd97f6db5',
            avatar: '/static/images/avatars/avatar_4.png',
            name: 'Neil Art Aces'
          },
          createdAt: moment()
            .subtract(2, 'hours')
            .toDate()
            .getTime(),
          message: 'Oh... so sentimental'
        }
      ],
      createdAt: moment()
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      isLiked: false,
      likes: 65,
      message: 'When you feel confident in your appearance, you radiate confidence. That confidence spills over into everything you do. Youre much more likely to approach people with a smile on your face and a positive attitude.'
    }
  ];

  return [200, { posts }];
});

mock.onGet('/api/social/feed').reply(() => {
  const posts: Post[] = [
    {
      id: '5e887fa38598b6fe61667757',
      author: {
        id: '5e88792be2d4cfb4bf0971d9',
        avatar: '/static/images/avatars/avatar_10.png',
        name: 'Mon Alburo'
      },
      comments: [
        {
          id: '5e887fb6c648772b52f860a8',
          author: {
            id: '5e8680e60cba5019c5ca6fda',
            avatar: '/static/images/avatars/avatar_12.png',
            name: 'Neil Art Aces'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          message: 'Power!!!'
        }
      ],
      createdAt: moment()
        .subtract(16, 'minutes')
        .toDate()
        .getTime(),
      isLiked: true,
      likes: 1,
      message: `Don't be contented. Don't be satisfied. Stay hungry. Stay ambitious. Stay striving. Stay succeeding.`
    },
    {
      id: '5e887faca2b7a1ddce01221a',
      author: {
        id: '5e86809283e28b96d2d38537',
        avatar: '/static/images/avatars/avatar_10.png',
        name: 'Mon Alburo'
      },
      comments: [
        {
          id: '5e887fc17162ba254da30771',
          author: {
            id: '5e887b7602bdbc4dbb234b27',
            avatar: '/static/images/avatars/avatar_12.png',
            name: 'Neil Art Aces'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          message: 'Power!!!'
        },
        // {
        //   id: '5e887fc759bebe8d5d54a2e5',
        //   author: {
        //     id: '5e887a1fbefd7938eea9c981',
        //     avatar: '/static/images/avatars/avatar_8.png',
        //     name: 'Miller Edwards'
        //   },
        //   createdAt: moment()
        //     .subtract(2, 'hours')
        //     .toDate()
        //     .getTime(),
        //   message: 'Hmm, honestly this looks nice but I would change the shadow though'
        // }
      ],
      createdAt: moment()
        .subtract(4, 'hours')
        .toDate()
        .getTime(),
      isLiked: true,
      likes: 24,
      media: '/static/images/posts/post_1.png',
      message: 'Enroll Now!'
    },
    {
      id: '5e887faf03e78a5359765636',
      author: {
        id: '5e86809283e28b96d2d38537',
        avatar: '/static/images/avatars/avatar_12.png',
        name: 'Neil Art Aces'
      },
      comments: [
        {
          id: '5e887fde4992eca63b9e9ef5',
          author: {
            id: '5e8877da9a65442b11551975',
            name: 'Kim Toluan',
            avatar: '/static/images/avatars/avatar_6.png'
          },
          createdAt: moment()
            .subtract(3, 'hours')
            .toDate()
            .getTime(),
          message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`
        },
        {
          id: '5e887feb11b7add1ebfcca78',
          author: {
            id: '5e887b209c28ac3dd97f6db5',
            name: 'Neil Art Aces',
            avatar: '/static/images/avatars/avatar_12.png'
          },
          createdAt: moment()
            .subtract(2, 'hours')
            .toDate()
            .getTime(),
          message: '❤️😎🤝'
        }
      ],
      createdAt: moment()
        .subtract(7, 'hours')
        .toDate()
        .getTime(),
      isLiked: false,
      likes: 65,
      message: 'When you feel confident in your appearance, you radiate confidence. That confidence spills over into everything you do. Youre much more likely to approach people with a smile on your face and a positive attitude.'
    }
  ];

  return [200, { posts }];
});
