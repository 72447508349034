/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
// import MailIcon from '@mui/icons-material/Mail';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';
import MentorIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import FullNameModal from 'src/components/FullNameModal';
import { isAdmin, isMember } from 'src/lib/util';
import { Roles, User } from 'src/types/user';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  roles?: Roles[];
}

interface Section {
  items: Item[];
  subheader: string;
  roles?: Roles[];
}

const sections: Section[] = [
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Dashboard',
        href: '/app/overview',
        icon: DashboardIcon,
        roles: [Roles.member]
      },
      {
        title: 'Inbox',
        href: '/app/mail',
        icon: MailIcon,
        roles: [Roles.member]
      },
      {
        title: 'Elite Framework',
        href: '/app/management/elite-framework',
        icon: BriefcaseIcon,
        roles: [Roles.member]
      },
      {
        title: 'Elite Mentors Club',
        href: '/app/management/elite-mentors-club',
        icon: MentorIcon,
        roles: [Roles.member]
      },
      // {
      //   title: 'Affiliates',
      //   href: '/app/overview',
      //   icon: DashboardIcon,
      // },
      // {
      //   title: 'Affiliates',
      //   href: '/app/projects/browse',
      //   icon: BriefcaseIcon,
      // },
        // items: [
        //   {
        //     title: 'Overview',
        //     href: '/app/projects/overview'
        //   },
        //   {
        //     title: 'Browse Projects',
        //     href: '/app/projects/browse'
        //   },
        //   {
        //     title: 'Create Project',
        //     href: '/app/projects/create'
        //   },
        //   {
        //     title: 'View Project',
        //     href: '/app/projects/1'
        //   }
        // ]
      // },
      // {
      //   title: 'Social Platform',
      //   href: '/app/social',
      //   icon: ShareIcon,
      //   roles: [Roles.member],
      //   items: [
      //     {
      //       title: 'Feed',
      //       href: '/app/social/feed',
      //       roles: [Roles.member],
      //     },
      //     // {
      //     //   title: 'Chat',
      //     //   href: '/app/chat',
      //     // },
      //   ]
      // },
      // {
      //   title: 'Kanban',
      //   href: '/app/kanban',
      //   icon: TrelloIcon
      // },
      // {
      //   title: 'Mail',
      //   href: '/app/mail',
      //   icon: MailIcon
      // },
      // {
      //   title: 'Calendar',
      //   href: '/app/calendar',
      //   icon: CalendarIcon,
      //   roles: [Roles.member]
      //   // info: () => (
      //   //   <Chip
      //   //     color="secondary"
      //   //     size="small"
      //   //     label="Updated"
      //   //   />
      //   // )
      // },
      {
        title: 'Members',
        icon: UsersIcon,
        href: '/app/management/members',
        roles: [Roles.admin],
      },
      // {
      //   title: 'Chat',
      //   href: '/app/chat',
      //   icon: MessageCircleIcon,
      //   // info: () => (
      //   //   <Chip
      //   //     color="secondary"
      //   //     size="small"
      //   //     label="Updated"
      //   //   />
      //   // )
      // },
    ]
  },
  // {
  //   subheader: 'Reports',
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: '/app/reports/dashboard'
  //     },
  //     {
  //       title: 'Dashboard Alternative',
  //       icon: BarChartIcon,
  //       href: '/app/reports/dashboard-alternative'
  //     }
  //   ]
  // },
  
  //     {
  //       title: 'Products',
  //       icon: ShoppingCartIcon,
  //       href: '/app/management/products',
  //       items: [
  //         {
  //           title: 'List Products',
  //           href: '/app/management/products'
  //         },
  //         {
  //           title: 'Create Product',
  //           href: '/app/management/products/create'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Orders',
  //       icon: FolderIcon,
  //       href: '/app/management/orders',
  //       items: [
  //         {
  //           title: 'List Orders',
  //           href: '/app/management/orders'
  //         },
  //         {
  //           title: 'View Order',
  //           href: '/app/management/orders/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Invoices',
  //       icon: ReceiptIcon,
  //       href: '/app/management/invoices',
  //       items: [
  //         {
  //           title: 'List Invoices',
  //           href: '/app/management/invoices'
  //         },
  //         {
  //           title: 'View Invoice',
  //           href: '/app/management/invoices/1'
  //         }
  //       ]
  //     }
  //   ]
  // },

  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

function hasAccess(roles: string[], user: User) {
  return roles?.some(role => user?.roles?.find(r => r === role));
}

function renderNavItems({
  user,
  items,
  pathname,
  depth = 0
}: {
  user: User;
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => {
          if(item && user && hasAccess(item.roles, user)) {
            reduceChildRoutes({ user, acc, item, pathname, depth })
          }
          return acc;
        },
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  user,
  acc,
  pathname,
  item,
  depth
}: {
  user: User;
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          user,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user?.firstName} {user?.lastName}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {isAdmin(user) ? 'Admin' : isMember(user) && 'Member'}
              {/* Member ID:
              {' '}
              <Link
                component={RouterLink}
                to="/app/account"
              >
                {user?.memberId}
              </Link> */}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                user,
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        {/* <Divider /> */}
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius={1}
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
