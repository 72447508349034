import { Roles, User } from 'src/types/user';

export const isAdmin = (user: User) =>
  !!user?.roles?.find(r => r === Roles.admin);

export const isMember = (user: User) =>
  !!user?.roles?.find(r => r === Roles.member);

export const generatePin = () => Math.floor(Math.random() * 900000) + 100000;

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  // b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const alphaOnly = (event: any) => {
  var key = event.keyCode;
  return (key >= 65 && key <= 90) || key == 8;
};
