import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import { getAuth, createUserWithEmailAndPassword as FBcreateUserWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signInWithEmailAndPassword as FBsignInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, increment, collection, updateDoc, onSnapshot, getDoc, query, where, limit, getDocs } from 'firebase/firestore';
import { db } from 'src/lib/firebase-db';
import { zeroPad } from 'src/utils';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: (email: string, password: string, firstName: string, lastName: string, referralId: string) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  signInWithFacebook: () => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const auth = getAuth();

  const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
    return FBsignInWithEmailAndPassword(auth, email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
  };

  const signInWithFacebook = (): Promise<any> => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider)
  };

  const createUserWithEmailAndPassword = async (email: string, password: string, firstName: string, lastName: string, memberId: string): Promise<any> => {
    return FBcreateUserWithEmailAndPassword(auth, email, password).then((result) => {
      saveNewUser(result.user, firstName, lastName, memberId)
    });
  };

  const saveNewUser = async (user: any, firstName: string, lastName: string, memberId: string): Promise<any> => {
    return await setDoc(doc(db, "users", memberId), {
      uid: user.uid,
      email: user.email,
      firstName,
      lastName,
      memberId,
      active: true,
      activatedAt: new Date()
    }, { merge: true });
  }

  const logout = (): Promise<void> => {
    return signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.

        const docRef = query(collection(db, 'users'), where('uid', '==', user.uid), limit(1));
        const data = await getDocs(docRef);
        const fetchUser = data.docs[0].data();

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              avatar: user.photoURL,
              email: user.email,
              ...fetchUser
            }
          }
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInWithFacebook,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
