import React from 'react';
import { adaptV4Theme } from '@mui/material/styles';
import type { FC } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { QueryParamProvider } from 'use-query-params';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SettingsNotification from 'src/components/SettingsNotification';
import { AuthProvider } from 'src/contexts/FirebaseAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { jssPreset, StylesProvider } from '@mui/styles';
// import { db } from "./firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from './lib/firebase-db';



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


// const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  // const [users, setUsers] = React.useState([]);
  // const usersCollectionRef = collection(db, "users");
  
  // React.useEffect(() => {
  //   const getUsers = async () => {
  //     const data = await getDocs(usersCollectionRef);
  //     setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //   };
    
  //   getUsers();
  // }, []);
  // console.log("🚀 ~ file: App.tsx ~ line 52 ~ users", users)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StylesProvider 
          // jss={jss}
        >
          {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
            <SnackbarProvider
              dense
              maxSnack={3}
            >
              <Router history={history}>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <AuthProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    {/* <GoogleAnalytics />
                    <CookiesNotification />
                    <SettingsNotification /> */}
                    {renderRoutes(routes)}
                  </AuthProvider>
                </QueryParamProvider>
              </Router>
            </SnackbarProvider>
          {/* </MuiPickersUtilsProvider> */}
        </StylesProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
