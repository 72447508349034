export enum Roles {
  admin = 'Admin',
  member = 'Member'
}

export interface User {
  id: string;
  avatar: string;
  email: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  roles?: Roles[];
  [key: string]: any;
}
